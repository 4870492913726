nav {
    margin-top: 30px;
}
nav ul {
    display: flex;
    list-style: none;
    width: 50vw;
    height: 50px;
    margin: 0 auto;
    border-bottom: 1px solid #666;
}
nav ul li {
    flex: 1;
    text-align: center;
    height: 100%;
    line-height: 50px;
}

nav ul li:hover {
    background-color: #efeeee;
}

nav ul li a {
    text-decoration: none;
    display: block;
    height: 100%;
    color: #666;
}
/* クリックしたときに線をつけるときの疑似 */
nav ul li a.active {
    color: rgb(32, 88, 220);
    border-bottom: 3px solid  rgb(32, 88, 220);
}