*{
    margin: 0;
    padding: 0;
}

section {
    width: 50vw;
    margin: 10px auto ;
}
@media (max-width:1080px) {
    section {
        width: 90vw
    }
}

section.section ul {
    list-style: none;
}
section.section li.item {
    border-bottom: 1px solid #666;
}
section.section .item .title {
    padding: 20px 0px 20px 10px;
}
section.section .item .title h3 {
    font-size: 16px;
}
section.section .item .title p {
    color: #333;
    font-size: 12px;
    margin-top: 5px;
}
section.section .item a {
    display: inline-block;
    color: #2492be;
    margin-left: 20px;
    text-decoration: none;
    font-size: 12px;
    margin-bottom: 20px;
}