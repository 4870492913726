*{
    margin: 0;
    padding: 0;
}
h2 {
    margin-top: 50px;
    background-color: rgb(72, 72, 255);
    padding: 10px 0 10px 20px;
    color: white;
    letter-spacing: .5rem;
    font-size: 15px;
}

@media (max-width:1080px) {
    h2 {
        margin-top: 0;
    }
}
